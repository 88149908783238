import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LottieLogo from '../logo/lottielogo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Sidan hittades inte" />
    <div className="container wide page-404">
      <div className="content">
        <LottieLogo />

        <h1 className="title--large">404 - Sidan hittades inte</h1>
        <p>Sidan du letade efter finns inte,
        <Link to="../" className="link-arrow">gå till startsidan.</Link></p>
      </div>
    </div>


  </Layout>
)

export default NotFoundPage
